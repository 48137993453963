import React from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, SmallWave } from "@/components/index"

export default function About() {
  return (
    <Layout headerType="simple" headerTheme="light" footerTheme="light">
      <SEO title="About" description="" />
      <Box
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt="9.6rem"
      >
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          textAlign="center"
          flexDirection="column"
          height="100%"
        >
          <SmallWave display="block" mx="auto" mb="1.2rem" />
          <Heading level="2" textAlign="center" mb="2rem">
            About
          </Heading>
        </Box>
      </Box>
    </Layout>
  )
}
